import axios from 'axios'
import apiUrl from '../config'

const url = apiUrl + 'api/v1/orders/'

class OrderService {
  // 获取我的文档列表
  static getOrders (token) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(url + 'my', {
            headers: {
              authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            resolve(
              res.data
            )
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 获取一个订单
  static getOneOrder (id, token) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(url + id, {
            headers: {
              authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            resolve(res.data)
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 创建一个订单
  static createOrder (token, order) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(url + 'add', order, {
            headers: {
              authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            resolve(
              res.data
            )
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 获取支付宝QR Code
  static getAlipayQrCode (token, order) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(url + 'alipay', order, {
            headers: {
              authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            resolve(res.data)
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 查询某个订单的支付状态
  static queryOrderStatus (orderId) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(url + 'status/' + orderId)
          .then((res) => {
            resolve(
              res.data
            )
          })
      } catch (err) {
        reject(err)
      }
    })
  }
}

export default OrderService
