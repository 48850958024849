import axios from 'axios'
import apiUrl from '../config'

const url = apiUrl + 'api/v1/users/'

class UserService {
  // 注册用户
  static register (user) {
    return new Promise((resolve, reject) => {
      try {
        axios.post(url + 'register', user).then((res) => {
          resolve(res.data)
        })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 重置密码
  static reset (user) {
    return new Promise((resolve, reject) => {
      try {
        axios.post(url + 'reset', user).then((res) => {
          resolve(res.data)
        })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 用户登录
  static login (user) {
    return new Promise((resolve, reject) => {
      try {
        axios.post(url + 'login', user).then((res) => {
          resolve(res.data)
        })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 获取验证码
  static getCode (phone, type) {
    return new Promise((resolve, reject) => {
      try {
        axios.post(url + 'getcode', { phone, type }).then((res) => {
          resolve(res.data)
        })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 获取用户有效期
  static getExpireTime (phone) {
    return new Promise((resolve, reject) => {
      try {
        axios.get(url + 'expireTime/' + phone).then((res) => {
          resolve(res.data)
        })
      } catch (err) {
        reject(err)
      }
    })
  }
}

export default UserService
