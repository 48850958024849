import axios from 'axios'
import apiUrl from '../config'

const url = apiUrl + 'api/v1/docs/'

class DocService {
  // 获取我的文档列表
  static getDocs (token) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(url + 'my', {
            headers: {
              authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            resolve(
              res.data.map((doc) => ({
                ...doc,
                updatedAt: new Date(doc.updatedAt).toLocaleString('zh', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: false
                })
              }))
            )
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 获取删除文档列表
  static getRecycle (token) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(url + 'recycle', {
            headers: {
              authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            resolve(
              res.data.map((doc) => ({
                ...doc,
                updatedAt: new Date(doc.updatedAt).toLocaleString('chinese', {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                  hour12: false
                })
              }))
            )
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 获取一个文档
  static getOneDoc (id, token) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(url + id, {
            headers: {
              authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            resolve(res.data)
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 新增文档
  static addDoc (token, doc) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(url + 'add', doc, {
            headers: {
              authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            resolve(
              // res.data.map(doc => ({
              //   ...doc,
              //   updatedAt: new Date(doc.updatedAt)
              // }))
              res.data
            )
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 保存文档
  static updateDoc (token, doc) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(url + 'update', doc, {
            headers: {
              authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            resolve(
              // res.data.map(doc => ({
              //   ...doc,
              //   updatedAt: new Date(doc.updatedAt)
              // }))
              res.data
            )
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 导出文档为docx格式
  static exportToDocx (token, doc) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(url + 'exporttodocx', doc, {
            headers: {
              authorization: `Bearer ${token}`
            },
            responseType: 'blob'
          })
          .then((res) => {
            // Create a blob from the response data
            const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
            // Create a link element and trigger the download
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = doc.doc_title + '.docx'
            link.click()
            // Clean up the URL object
            window.URL.revokeObjectURL(link.href)
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 导出文档为pptx格式
  static exportToPptx (token, doc) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(url + 'exporttopptx', doc, {
            headers: {
              authorization: `Bearer ${token}`
            },
            responseType: 'blob'
          })
          .then((res) => {
            // Create a blob from the response data
            const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' })
            // Create a link element and trigger the download
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = doc.doc_title + '.pptx'
            link.click()
            // Clean up the URL object
            window.URL.revokeObjectURL(link.href)
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 删除文档
  static deleteDoc (token, id) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(url + 'delete/' + id, {
            headers: {
              authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            resolve(
              // res.data.map(doc => ({
              //   ...doc,
              //   updatedAt: new Date(doc.updatedAt)
              // }))
              res.data
            )
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 恢复文档
  static recoverDoc (token, id) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(url + 'recover/' + id, {
            headers: {
              authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            resolve(
              // res.data.map(doc => ({
              //   ...doc,
              //   updatedAt: new Date(doc.updatedAt)
              // }))
              res.data
            )
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 永久删除文档
  static permanentDeleteDoc (token, id) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(url + 'permanentdelete/' + id, {
            headers: {
              authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            resolve(
              // res.data.map(doc => ({
              //   ...doc,
              //   updatedAt: new Date(doc.updatedAt)
              // }))
              res.data
            )
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 查看是否允许创建文档
  static verifyCreate (token) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(url + 'verify', {
            headers: {
              authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            resolve(
              // res.data.map(doc => ({
              //   ...doc,
              //   updatedAt: new Date(doc.updatedAt)
              // }))
              res.data
            )
          })
      } catch (err) {
        reject(err)
      }
    })
  }
}

export default DocService
