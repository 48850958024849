import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Template from '../views/Template.vue'
import Document from '../views/Document.vue'
import Recycle from '../views/Recycle.vue'
import Contact from '../views/Contact.vue'
import Login from '../views/Login.vue'
import TempCanvas from '../views/TempCanvas.vue'
import DocCanvas from '../views/DocCanvas.vue'
import Price from '../views/Price.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/template',
    name: 'Template',
    component: Template
  },
  {
    path: '/document',
    name: 'Document',
    component: Document
  },
  {
    path: '/recycle',
    name: 'Recycle',
    component: Recycle
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/temp/:id',
    name: 'TempCanvas',
    component: TempCanvas
  },
  {
    path: '/doc/:id',
    name: 'DocCanvas',
    component: DocCanvas
  },
  {
    path: '/price',
    name: 'Price',
    component: Price
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  routes
})

export default router
