<template>
  <div id="app">
    <Header></Header>
    <main>
      <router-view />
    </main>
  </div>
</template>

<script>
import Header from './components/Header.vue'

export default {
  created () {
    // 在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener('beforeunload', () => {
      localStorage.setItem('isLogin', this.$store.getters.getIsLogin)
      localStorage.setItem('userGroup', this.$store.getters.getUserGroup)
      // 如果有效期是null的话，就不用保存，只有有到期日的时候再保存到localStorage里
      if (this.$store.getters.getUserGroupExpiredAt !== null) {
        localStorage.setItem('userGroupExpiredAt', this.$store.getters.getUserGroupExpiredAt)
      }
      localStorage.setItem('phone', this.$store.getters.getPhone)
    })
    // 保存用户组
    if (localStorage.getItem('userGroup')) {
      let group = null
      if (localStorage.getItem('userGroup') === '免费用户') {
        group = 'normal'
      } else if (localStorage.getItem('userGroup') === 'VIP') {
        group = 'vip'
      } else if (localStorage.getItem('userGroup') === '管理员') {
        group = 'admin'
      }
      this.$store.dispatch('setUserGroup', group)
    }
    // 保存用户组到期时间
    if (localStorage.getItem('userGroupExpiredAt')) {
      this.$store.dispatch(
        'setUserGroupExpiredAt',
        localStorage.getItem('userGroupExpiredAt')
      )
    }
    // 在页面加载时读取localStorage里的状态信息
    if (localStorage.getItem('isLogin')) {
      this.$store.dispatch(
        'setIsLogin',
        JSON.parse(localStorage.getItem('isLogin'))
      )
    }
    // 在页面加载时读取localStorage里的状态信息
    if (localStorage.getItem('phone')) {
      this.$store.dispatch('setPhone', localStorage.getItem('phone'))
    }
  },
  components: {
    Header
  }
}
</script>

<style scoped>
#app {
  font-family: '微软雅黑', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
  color: #000;
}

main {
  width: 1200px;
  margin: 0 auto;
  padding-top: 30px;
  clear: both;
}
</style>
