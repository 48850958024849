<template lang="">
  <div class="qrcode">
    如您有任何问题或建议，请扫码添加用户支持微信：<br /><br /><br />
    <img src="../assets/qrcode.jpg" />
  </div>
</template>

<script>
export default {
  created () {
    document.title = '用户支持 - 帛马'
  }
}
</script>

<style lang="scss" scoped>
.qrcode {
  text-align: center;
  padding: 40px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(99, 102, 241, 0.08);
  margin: 10px auto;
  max-width: 400px;
  font-family: '微软雅黑';
  font-size: 16px;
  color: #4b5563;
}
.qrcode img {
  height: 120px;
  width: 120px;
  border-radius: 12px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.1);
  &:hover {
    transform: scale(1.02);
    box-shadow: 0 8px 24px rgba(99, 102, 241, 0.15);
  }
}
</style>
