<template lang="">
  <div class="content" style="margin-top: 15px">
    <div class="top-warn-con" style="display: none"></div>
    <h2 style="color: #333; margin-bottom: 30px;">用框架，助力思考</h2>
    <!-- <h4 class="h4">VIP用户无文档数量限制</h4> -->
    <div class="product-item ac">
      <h2> 免费用户</h2>
      <div class="product-price">
        <span style="font-size: 30px">¥0.00</span>
      </div>
      <div class="price-des">元</div>
      <div class="product-buy" style="padding-bottom: 11px;">
        <span
          style="
            background: #fff;
            color: #777;
            width: auto;
            font-size: 14px;
            line-height: 16px;
            margin-top: 10px;
          "
          >永久免费</span
        >
      </div>
      <div class="product-attr">
        <div class="item">
          文档数量 <span><b>3个 </b></span>
        </div>
        <div class="item">使用免费模板 <span>√</span></div>
        <div class="item"> &nbsp; <span></span></div>
        <div class="item"> &nbsp; <span></span></div>
        <div class="item"> &nbsp; <span></span></div>
        <div class="item"> &nbsp; <span></span></div>
        <div class="item"> &nbsp; <span></span></div>
        <div class="item"> &nbsp; <span></span></div>
        <!-- <div class="item">保存文档为图片 <span>支持</span></div> -->
      </div>
    </div>
    <div class="product-item">
      <h2><span class="icons"></span> VIP用户</h2>
      <div class="product-price">
        <span style="font-size: 30px">¥19.90</span>
      </div>
      <div class="price-des">元 / 月</div>
      <div class="product-buy">
        <el-row>
          <el-button type="success" size="small" @click="confirmOrder('month')"
            >立即购买</el-button
          >
        </el-row>
      </div>
      <div class="product-attr">
        <div class="item">
          文档数量 <span><b>无限 </b></span>
        </div>
        <div class="item"><b>使用全部模板 </b><span>√</span></div>
        <!-- <div class="item">修改模块标题 <span>√</span></div> -->
        <div class="item">增加/删除模块 <span>√</span></div>
        <div class="item">移动模块位置 <span>√</span></div>
        <div class="item">调整模块大小 <span>√</span></div>
        <div class="item"><b>导出Word文档</b> <span>√</span></div>
        <div class="item"><b>导出PPT文档</b> <span>√</span></div>
        <div class="item"><b>导出图片</b> <span>√</span></div>
        <!-- <div class="item">7天内无理由退款 <span>√</span></div> -->
      </div>
    </div>
    <div class="product-item">
      <h2><span class="icons"></span> VIP用户</h2>
      <div class="product-price">
        <span style="font-size: 30px">¥69.90&nbsp;<span style="text-decoration:line-through; font-size: 16px; color: #acacac;">&nbsp;¥238.80&nbsp;</span></span>
      </div>
      <div class="price-des">元 / 年</div>
      <div class="product-buy">
        <el-row>
          <el-button type="success" size="small" @click="confirmOrder('year')"
            >立即购买</el-button
          >
        </el-row>
      </div>
      <div class="product-attr">
        <div class="item">
          文档数量 <span><b>无限 </b></span>
        </div>
        <div class="item"><b>使用全部模板 </b><span>√</span></div>
        <!-- <div class="item">修改模块标题 <span>√</span></div> -->
        <div class="item">增加/删除模块 <span>√</span></div>
        <div class="item">移动模块位置 <span>√</span></div>
        <div class="item">调整模块大小 <span>√</span></div>
        <div class="item"><b>导出Word文档</b> <span>√</span></div>
        <div class="item"><b>导出PPT文档</b> <span>√</span></div>
        <div class="item"><b>导出图片</b> <span>√</span></div>
        <!-- <div class="item">7天内无理由退款 <span>√</span></div> -->
      </div>
    </div>
    <div style="margin: 40px 0 50px; color: #666"></div>

    <el-dialog
      title="购买会员"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
      :before-close="handleClose"
    >
      <div class="purchase-box">
        <p>订单号：{{ order.order_id }}</p>
        <p>规格：{{ order.name }}</p>
        <p>价格：{{ order.price }}元</p>
        <!-- <p>购买后到期日：{{ expiredAt }}</p> -->
        <br />
        <p>请使用<span style="color: #027AFF; font-weight: bold;">支付宝</span>扫码支付：</p>
        <div class="qrcode">
          <img :src="qrcode" />
        </div>
      </div>
    </el-dialog>

    <el-dialog
      title="购买成功"
      :visible.sync="successDialogVisible"
      width="30%"
      center>
      <div style="text-align: center">
        <span>您购买的VIP会员有效期已生效！</span>
        <br><br><br>
        <img src="../assets/payment-success.jpg" alt="" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" @click="navigateToMyDoc">前往我的文档</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import OrderService from '../util/OrderService'
import TokenService from '../util/TokenService'
import QRCode from 'qrcode'
import apiUrl from '../config'
import UserService from '../util/UserService'

export default {
  data () {
    return {
      token: null,
      centerDialogVisible: false,
      successDialogVisible: false,
      order: {
        name: null,
        pay_type: 'alipay',
        price: null,
        order_id: null,
        order_uid: localStorage.getItem('user_id'),
        notify_url: apiUrl + 'api/v1/orders/update',
        // more: null,
        expire: 7200
      },
      qrcode: ''
    }
  },
  created () {
    document.title = '价格 - 帛马'
  },
  methods: {
    // 支付成功后跳转至我的文档页
    navigateToMyDoc () {
      this.successDialogVisible = false
      this.$router.push('/document')
    },
    // 生成订单号
    generateOrderId () {
      const year = new Date().getFullYear()
      const month = new Date().getMonth() + 1
      const date = new Date().getDate()
      const randomNum = Math.round(Math.random() * 100000)
      // 创建订单号，用0对整齐
      let orderId = ''
      if (month < 10 && date < 10) {
        orderId = 'BM' + year + '0' + month + '0' + date + randomNum
      } else if (month < 10) {
        orderId = 'BM' + year + '0' + month + date + randomNum
      } else if (date < 10) {
        orderId = 'BM' + year + month + '0' + date + randomNum
      } else {
        orderId = 'BM' + year + month + date + randomNum
      }
      return orderId
    },
    // 获取二维码
    async generateQR (text) {
      QRCode.toDataURL(text)
        .then((url) => {
          this.qrcode = url
        })
        .catch((err) => {
          console.error(err)
        })
    },
    // 每5秒查询一次订单状态
    async queryOrderStatus (orderId) {
      // 3分钟内未支付成功，停止查询，直接把二维码关掉让用户重新create订单
      window.timeout = setTimeout(() => {
        clearInterval(window.timer)
        this.centerDialogVisible = false
        this.$message({
          message: '支付超时，请重新购买',
          type: 'warning',
          offset: 52
        })
      }, 1000 * 60 * 3)
      // 每5秒去取一次订单状态
      window.timer = setInterval(async () => {
        const orderStatus = await OrderService.queryOrderStatus(orderId)
        if (orderStatus === 'completed') {
          // 如果订单completed，说明支付完成了，获取新的有效期
          const newExpireTime = await UserService.getExpireTime(this.$store.getters.getPhone)
          // 更新有效期到vuex里
          await this.$store.dispatch('setUserGroupExpiredAt', newExpireTime)
          // 更新用户组（这里最好用后台传过来的，后面再改吧）
          await this.$store.dispatch('setUserGroup', 'vip')
          // 停止倒计时
          clearInterval(window.timer)
          clearTimeout(window.timeout)
          // 隐藏支付弹窗，显示购买成功弹窗
          this.centerDialogVisible = false
          this.successDialogVisible = true
        }
      }, 5000)
    },
    // 手动关闭购买弹窗时，不再查询订单支付状态
    handleClose () {
      clearInterval(window.timer)
      clearTimeout(window.timeout)
      this.centerDialogVisible = false
    },
    // 点击购买按钮后生成订单并展示二维码给用户支付
    async confirmOrder (variant) {
      // 先看是否登录了
      if (!this.$store.getters.getIsLogin) {
        localStorage.setItem('from', this.$route.path)
        return this.$router.push('/login')
      }
      // 刷新token
      const token = await TokenService.checkToken()
      if (!token) {
        localStorage.setItem('from', this.$route.path)
        return this.$router.push('/login')
      }
      this.token = token
      // 生成订单信息
      this.order.order_id = this.generateOrderId()
      if (variant === 'month') {
        this.order.name = '包月VIP会员'
        this.order.price = '19.9'
      } else if (variant === 'year') {
        this.order.name = '包年VIP会员'
        this.order.price = '69.9'
      }
      // 先在后台生成订单后才能支付
      const orderToSave = {
        orderId: this.order.order_id,
        productName: this.order.name,
        variant,
        price: this.order.price
      }
      try {
        // 先在后台生成订单
        const createResult = await OrderService.createOrder(
          this.token,
          orderToSave
        )
        // 如果返回订单创建成功，去获取二维码
        if (createResult.code === 60000) {
          const data = await OrderService.getAlipayQrCode(
            this.token,
            this.order
          )
          // 如果发生错误，去登陆页
          if (data.code === '70009') {
            localStorage.setItem('from', this.$route.path)
            return this.$router.push('/login')
          }
          // 如果一切正常，生成二维码后，展示订单信息弹窗
          await this.generateQR(data.info.qr)
          this.centerDialogVisible = true
          // 开始查询支付状态
          this.queryOrderStatus(this.order.order_id)
        } else {
          this.$message({
            message: '暂时无法下单，请稍后重试',
            type: 'warning',
            offset: 52
          })
        }
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>

<style scoped>
.content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  margin-top: 10px;
  text-align: center;
  background-color: #f8fafc;
  padding: 30px 20px;
  border-radius: 16px;
}

.content > h2 {
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  margin: 20px 0 40px 0;
  color: #4f46e5;
  background: linear-gradient(120deg, #6366f1 0%, #8b5cf6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.product-item {
  display: inline-block;
  width: 300px;
  position: relative;
  overflow: hidden;
  min-height: 456px;
  background: #fff;
  margin: 0 15px;
  border-radius: 16px;
  vertical-align: top;
  text-align: left;
  transition: all 0.3s ease;
  border: 1px solid rgba(99, 102, 241, 0.1);
  box-shadow: 0 4px 20px rgba(99, 102, 241, 0.08);
}

.product-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(99, 102, 241, 0.15);
}

.product-item h2 {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
  color: #6366f1;
}

.product-price {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 5px;
  position: relative;
  font-size: 17px;
  height: 34px;
  color: #4f46e5;
}

.price-des {
  font-size: 14px;
  color: #6b7280;
  text-align: center;
}

.product-buy {
  margin-top: 25px;
  text-align: center;
  position: relative;
}

.product-buy .el-button--success {
  background: linear-gradient(120deg, #6366f1 0%, #8b5cf6 100%);
  border: none;
  border-radius: 8px;
  padding: 10px 24px;
  font-size: 15px;
  transition: all 0.3s ease;
}

.product-buy .el-button--success:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);
}

.product-attr {
  border-top: 1px solid rgba(99, 102, 241, 0.1);
  margin-top: 34px;
  padding: 30px 40px;
}

.product-item .item {
  line-height: 32px;
  font-size: 15px;
  color: #4b5563;
  clear: both;
}

.product-attr span {
  float: right;
  clear: right;
  color: #6366f1;
  font-weight: 500;
}

/* 支付弹窗样式 */
.purchase-box {
  padding: 20px 0 30px;
}

.purchase-box p {
  margin-left: 30px;
  font-size: 16px;
  margin-top: 8px;
  color: #4b5563;
}

.qrcode {
  text-align: center;
  background: #f8fafc;
  padding: 20px;
  border-radius: 12px;
  margin: 20px;
}

.qrcode img {
  width: 160px;
  height: 160px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(99, 102, 241, 0.1);
}

/* 对话框样式优化 */
/deep/ .el-dialog {
  border-radius: 16px;
  overflow: hidden;
}

/deep/ .el-dialog__header {
  background: linear-gradient(120deg, #6366f1 0%, #8b5cf6 100%);
  padding: 15px 20px;
}

/deep/ .el-dialog__title {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}
</style>
