import axios from 'axios'
import apiUrl from '../config'

const url = apiUrl + 'api/v1/token/'

class TokenService {
  // 刷新token
  static refresh (refreshToken) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(url + 'refresh', {
            headers: {
              authorization: `Bearer ${refreshToken}`
            }
          })
          .then((res) => {
            resolve(res.data)
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 查token是否有效
  static check (token) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(url + 'check', {
            headers: {
              authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            resolve(res.data)
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 给每个页面的查验或者刷新token服务
  static async checkToken () {
    // 首先看是不是登录了，如果没有登录，返回false
    if (!localStorage.getItem('token')) return false
    // 如果有，拿缓存的token去校验一下是否有效
    const token = localStorage.getItem('token')
    const checkResult = await this.check(token)
    // 如果结果不是70009也不是70008，说明token有效，直接返回token
    if (checkResult.code !== '70009' && checkResult.code !== '70008') {
      return token
    }
    // 如果token无效，返回false
    if (checkResult.code === '70009') return false
    // 如果token过期，用freshToken再试一次获得新token
    if (checkResult.code === '70008') {
      // 使用refreshToken去获取新的token
      const refreshToken = localStorage.getItem('refreshToken')
      const refreshResult = await this.refresh(refreshToken)
      // 如果refreshToken也出错或者过期，重新去登录
      if (refreshResult.code === '70009' || refreshResult.code === '70008') {
        return false
      }
      // 刷新成功，更新token，并返回新的token
      localStorage.setItem('token', refreshResult.token)
      localStorage.setItem('refreshToken', refreshResult.refreshToken)
      return refreshResult.token
    }
  }
}

export default TokenService
