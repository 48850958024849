<template lang="">
  <div>
    <div class="content">
      <h1>请选择框架模板：</h1>
      <div class="category">
        <h2>商业模式</h2>
        <div class="line" />
        <ul>
          <div
            v-for="temp in businessModelTempList"
            :key="temp._id"
            @click="goToTempCanvas(temp._id)"
          >
            <li>
              <div>{{ temp.template_title }}</div>
              <div class="tag" v-if="temp.isFree">免费</div>
            </li>
          </div>
        </ul>
        <h2>战略规划</h2>
        <div class="line" />
        <ul>
          <div
            v-for="temp in strategyTempList"
            :key="temp._id"
            @click="goToTempCanvas(temp._id)"
          >
            <li>
              <div>{{ temp.template_title }}</div>
              <div class="tag" v-if="temp.isFree">免费</div>
            </li>
          </div>
        </ul>
        <h2>业务管理</h2>
        <div class="line" />
        <ul>
          <div
            v-for="temp in businessTempList"
            :key="temp._id"
            @click="goToTempCanvas(temp._id)"
          >
            <li>
              <div>{{ temp.template_title }}</div>
              <div class="tag" v-if="temp.isFree">免费</div>
            </li>
          </div>
        </ul>
        <h2>团队管理</h2>
        <div class="line" />
        <ul>
          <div
            v-for="temp in teamTempList"
            :key="temp._id"
            @click="goToTempCanvas(temp._id)"
          >
            <li>
              <div>{{ temp.template_title }}</div>
              <div class="tag" v-if="temp.isFree">免费</div>
            </li>
          </div>
        </ul>
        <h2>营销推广</h2>
        <div class="line" />
        <ul>
          <div
            v-for="temp in marketingTempList"
            :key="temp._id"
            @click="goToTempCanvas(temp._id)"
          >
            <li>
              <div>{{ temp.template_title }}</div>
              <div class="tag" v-if="temp.isFree">免费</div>
            </li>
          </div>
        </ul>
        <h2>问题分析</h2>
        <div class="line" />
        <ul>
          <div
            v-for="temp in problemSolvingTempList"
            :key="temp._id"
            @click="goToTempCanvas(temp._id)"
          >
            <li>
              <div>{{ temp.template_title }}</div>
              <div class="tag" v-if="temp.isFree">免费</div>
            </li>
          </div>
        </ul>
      </div>
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import DocService from '../util/DocService'
import TempService from '../util/TempService'
import TokenService from '../util/TokenService'

export default {
  data: function () {
    return {
      strategyTempList: [],
      businessTempList: [],
      marketingTempList: [],
      problemSolvingTempList: []
    }
  },
  async created () {
    document.title = '模板中心 - 帛马'
    const tempList = await TempService.getTemps()
    this.businessModelTempList = tempList.filter(temp => {
      return temp.category === 'businessModel'
    })
    this.strategyTempList = tempList.filter(temp => {
      return temp.category === 'strategy'
    })
    this.businessTempList = tempList.filter(temp => {
      return temp.category === 'business'
    })
    this.teamTempList = tempList.filter(temp => {
      return temp.category === 'team'
    })
    this.marketingTempList = tempList.filter(temp => {
      return temp.category === 'marketing'
    })
    this.problemSolvingTempList = tempList.filter(temp => {
      return temp.category === 'problem-solving'
    })
  },
  methods: {
    // 如果没有登录，就可以随便
    async goToTempCanvas (id) {
      // 查看是否登录了，登录了才去check是否超过3个文档
      if (this.$store.getters.getIsLogin) {
        // 获取或更新token
        const token = await TokenService.checkToken()
        // 确认是否已经超过文档数量限制
        const verifyCreate = await DocService.verifyCreate(token)
        // 如果返回70002，表示免费用户已经超限
        if (verifyCreate.code === '70002') {
          this.$confirm('您的免费文档数量已用完，请升级后继续使用。', '提示', {
            confirmButtonText: '查看详情',
            cancelButtonText: '取消',
            type: 'warning'
          })
            .then(() => {
              this.$router.push('/price')
            })
            .catch(() => {})
        } else if (verifyCreate.code === '70001') {
          // 如果返回70001，表示VIP用户过期了
          this.$confirm(
            '您的VIP权限已过期，无法创建新文档，是否续费？',
            '提示',
            {
              confirmButtonText: '立即续费',
              cancelButtonText: '取消',
              type: 'warning'
            }
          )
            .then(() => {
              this.$router.push('/price')
            })
            .catch(() => {})
        } else {
          // 如果没有超过，直接去详情页
          this.$router.push('/temp/' + id)
        }
      } else {
        // 如果没有登录，也可以去详情页
        this.$router.push('/temp/' + id)
      }
    }
  }
}
</script>

<style scope="scoped">
.content {
  text-align: left;
}
.content h1 {
  font-family: '微软雅黑';
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
}
.category h2 {
  font-family: '微软雅黑';
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0 10px 10px;
  clear: both;
  text-align: left;
}
.category hr {
  width: 100%;
  height: 0px;
  border-bottom: 1px lightgray solid;
  margin: 0 auto;
}
.category ul {
  display: flex;
  flex-wrap: wrap;
}
.category ul li {
  width: 120px;
  height: 64px;
  margin: 10px 8px 10px 8px;
  cursor: pointer;

  /* 使用更柔和的渐变背景 */
  background: linear-gradient(120deg, #6366f1 0%, #8b5cf6 100%);

  /* 更大的圆角和精致的阴影 */
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);

  padding: 12px 16px;
  color: white;
  font-family: '微软雅黑';
  font-size: 15px;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: center;

  /* 更平滑的过渡效果 */
  transition: all 0.3s ease;

  text-align: center;

  /* 添加边框光晕效果 */
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.category ul li:hover {
  transform: translateY(-4px);
  box-shadow: 0 12px 24px rgba(99, 102, 241, 0.3);
  background: linear-gradient(120deg, #4f46e5 0%, #7c3aed 100%);
}
.tag {
  color: #fff;
  width: 30px;
  height: 17px;
  position: absolute;
  margin-top: -70px;
  font-size: 12px;
  margin-left: 120px;
  border: 1px solid #fff;
  padding: 2px 4px 2px 4px;
  border-bottom-left-radius: 3px;
  /* -webkit-transform: scale(0.9); */
}
.line {
  width: 100%;
  height: 0px;
  border-bottom: 1px lightgray solid;
  margin: 0 auto;
}
</style>
