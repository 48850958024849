let apiUrl = ''

if (location.hostname === '127.0.0.1' || location.hostname === 'localhost') {
  apiUrl = 'http://localhost:5000/'
} else if (location.hostname === 'space.boomar.cn') {
  if (window.location.protocol === 'https:') {
    apiUrl = 'https://bff.boomar.cn/'
  } else {
    apiUrl = 'http://bff.boomar.cn/'
  }
}

export default apiUrl
