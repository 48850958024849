import Vue from 'vue'

class MsgService {
  // 通过code的第一个字符获得type
  static getTypeByCode (code) {
    code = String(code.split('', 1))
    switch (code) {
      case '6':
        return 'success'
      case '7':
        return 'warning'
      case '8':
        return 'error'
    }
  }

  // 根据code和messaeg来统一处理提示
  static showMsgByCode (code, message) {
    Vue.prototype.$message({
      message: message,
      type: this.getTypeByCode(code),
      offset: 52
    })
  }
}

export default MsgService
