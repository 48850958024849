const user = {
  state: {
    isLogin: false,
    userGroup: null,
    phone: null,
    userGroupExpiredAt: null
  },
  mutations: {
    setIsLogin (state, isLogin) {
      state.isLogin = isLogin
    },
    setUserGroup (state, userGroup) {
      if (userGroup === 'normal') {
        state.userGroup = '免费用户'
      } else if (userGroup === 'vip') {
        state.userGroup = 'VIP'
      } else if (userGroup === 'admin') {
        state.userGroup = '管理员'
      } else {
        state.userGroup = null
      }
    },
    setPhone (state, phone) {
      state.phone = phone
    },
    setUserGroupExpiredAt (state, userGroupExpiredAt) {
      if (userGroupExpiredAt === null) {
        state.userGroupExpiredAt = null
      } else {
        state.userGroupExpiredAt = userGroupExpiredAt
      }
    }
  },
  actions: {
    setIsLogin ({ commit }, data) {
      commit('setIsLogin', data)
    },
    setUserGroup ({ commit }, data) {
      commit('setUserGroup', data)
    },
    setPhone ({ commit }, data) {
      commit('setPhone', data)
    },
    setUserGroupExpiredAt ({ commit }, data) {
      commit('setUserGroupExpiredAt', data)
    }
  },
  getters: {
    getIsLogin (state) {
      return state.isLogin
    },
    getUserGroup (state) {
      return state.userGroup
    },
    getPhone (state) {
      return state.phone
    },
    getUserGroupExpiredAt (state) {
      return state.userGroupExpiredAt
    }
  }
}
export default user
