import axios from 'axios'
import apiUrl from '../config'

const url = apiUrl + 'api/v1/temps/'

class TempService {
  // 获取模板列表
  static getTemps () {
    return new Promise((resolve, reject) => {
      try {
        axios.get(url + 'all').then((res) => {
          resolve(
            // res.data.map((temp) => ({
            //   ...temp,
            //   createdAt: new Date(temp.createdAt)
            // }))
            res.data
          )
          //   console.log(res.data)
        })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 获取一个模板
  static getOneTemp (id) {
    return new Promise((resolve, reject) => {
      try {
        axios.get(url + id).then((res) => {
          resolve(res.data)
        })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 创建一个模板
  static createTemp (token, temp) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(
            url + 'add',
            temp,
            {
              headers: {
                authorization: `Bearer ${token}`
              }
            }
          )
          .then((res) => {
            resolve(res.data)
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 保存模板
  static updateTemp (token, temp) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .post(url + 'update', temp, {
            headers: {
              authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            resolve(
              // res.data.map(doc => ({
              //   ...doc,
              //   updatedAt: new Date(doc.updatedAt)
              // }))
              res.data
            )
          })
      } catch (err) {
        reject(err)
      }
    })
  }

  // 删除模板
  static deleteTemp (token, id) {
    return new Promise((resolve, reject) => {
      try {
        axios
          .get(url + 'delete/' + id, {
            headers: {
              authorization: `Bearer ${token}`
            }
          })
          .then((res) => {
            resolve(
              // res.data.map(doc => ({
              //   ...doc,
              //   updatedAt: new Date(doc.updatedAt)
              // }))
              res.data
            )
          })
      } catch (err) {
        reject(err)
      }
    })
  }
}

export default TempService
