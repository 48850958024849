<template lang="">
  <div
    class="container"
    :class="active ? 'right-panel-active' : 'left-panel-active'"
    id="container"
  >
    <div class="form-container sign-up-container" v-show="!isReset">
      <el-form
        :model="regForm"
        :rules="rules"
        ref="regForm"
        label-width="0px"
        class="form"
        autocomplete="off"
      >
        <h2>注册账号</h2>
        <el-form-item prop="phone">
          <el-input
            v-model="regForm.phone"
            placeholder="请输入手机号"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <div style="display: flex; justify-content: space-between; width: 192px; height: 40px;">
          <el-form-item prop="inputCaptcha" style="width: 100px">
            <el-input
              v-model="inputCaptcha"
              placeholder="校验码"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <canvas width="80" height="24" id="registerCaptcha"></canvas>
        </div>
        <div style="display: flex; justify-content: space-between; width: 192px; margin-top: 22px;">
          <el-form-item prop="verifyCode" style="width: 100px">
            <el-input
              v-model="regForm.verifyCode"
              placeholder="短信验证码"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <button
            type="button"
            :class=" isDisabled ? 'code-btn-disabled' : 'code-btn' "
            @click="sendVerifyCode(regForm.phone, 'register', 'registerCaptcha')"
            :disabled = "isDisabled"
          >
            {{ isDisabled ? `重新发送(${timeCount}s)` : '获取验证码' }}
          </button>
        </div>
        <el-form-item prop="password">
          <el-input
            type="password"
            v-model="regForm.password"
            autocomplete="new-password"
            placeholder="密码6-16位含字母数字"
          ></el-input>
        </el-form-item>
        <el-form-item prop="checkPass">
          <el-input
            type="password"
            v-model="regForm.checkPass"
            autocomplete="new-password"
            placeholder="请再次输入密码"
            @keyup.enter.native="submitForm('regForm')"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('regForm')"
            >提交</el-button
          >
          <el-button @click="resetForm('regForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <div class="form-container sign-up-container" v-show="isReset">
      <el-form
        :model="regForm"
        :rules="rules"
        ref="resetForm"
        label-width="0px"
        class="form"
        autocomplete="off"
      >
        <h2>重置密码</h2>
        <el-form-item prop="phone">
          <el-input
            v-model="regForm.phone"
            placeholder="请输入手机号"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <!-- <div style="display: flex; justify-content: center; width: 186px">
          <el-form-item prop="verifyCode" style="width: 100px">
            <el-input
              v-model="regForm.verifyCode"
              placeholder="验证码"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <button
            type="button"
            :class=" isDisabled ? 'code-btn-disabled' : 'code-btn' "
            @click="sendVerifyCode(regForm.phone, 'reset')"
            :disabled = "isDisabled"
          >
            {{ isDisabled ? `重新发���(${timeCount}s)` : '获取验证码' }}
          </button>
        </div> -->
        <div style="display: flex; justify-content: space-between; width: 192px; height: 40px;">
          <el-form-item prop="inputCaptcha" style="width: 100px">
            <el-input
              v-model="inputCaptcha"
              placeholder="校验码"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <canvas width="80" height="24" id="resetCaptcha"></canvas>
        </div>
        <div style="display: flex; justify-content: space-between; width: 192px; margin-top: 22px;">
          <el-form-item prop="verifyCode" style="width: 100px">
            <el-input
              v-model="regForm.verifyCode"
              placeholder="短信验证码"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <button
            type="button"
            :class=" isDisabled ? 'code-btn-disabled' : 'code-btn' "
            @click="sendVerifyCode(regForm.phone, 'reset', 'resetCaptcha')"
            :disabled = "isDisabled"
          >
            {{ isDisabled ? `重新发送(${timeCount}s)` : '获取验证码' }}
          </button>
        </div>
        <el-form-item prop="password">
          <el-input
            type="password"
            v-model="regForm.password"
            autocomplete="new-password"
            placeholder="密码6-16位含字母数字"
          ></el-input>
        </el-form-item>
        <el-form-item prop="checkPass">
          <el-input
            type="password"
            v-model="regForm.checkPass"
            autocomplete="new-password"
            placeholder="请再次输入密码"
            @keyup.enter.native="submitForm('resetForm')"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('resetForm')"
            >提交</el-button
          >
          <el-button @click="resetForm('resetForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="form-container sign-in-container">
      <el-form
        :model="loginForm"
        :rules="rules"
        ref="loginForm"
        label-width="0px"
        class="form"
      >
        <h2>登录</h2>
        <el-form-item prop="phone">
          <el-input
            v-model="loginForm.phone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <!-- <div style="display: flex; justify-content: center; width: 200px" v-if="!passwordLogin">
          <el-form-item prop="verifyCode" style="width: 100px">
            <el-input
              v-model="loginForm.verifyCode"
              placeholder="验证码"
            ></el-input>
          </el-form-item>
          <button
            type="button"
            :class=" isDisabled ? 'code-btn-disabled' : 'code-btn' "
            @click="sendVerifyCode(loginForm.phone, 'login')"
            :disabled = "isDisabled"
          >
            {{ isDisabled ? `重新发送(${timeCount}s)` : '获取验证码' }}
          </button>
        </div> -->
        <div style="display: none; justify-content: space-between; width: 192px; height: 40px;" id="captchaDiv">
          <el-form-item prop="inputCaptcha" style="width: 100px">
            <el-input
              v-model="inputCaptcha"
              placeholder="校验码"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <canvas width="80" height="24" id="loginCaptcha"></canvas>
        </div>
        <div style="display: flex; justify-content: space-between; width: 192px; margin-top: 22px;" v-if="!passwordLogin">
          <el-form-item prop="verifyCode" style="width: 100px">
            <el-input
              v-model="regForm.verifyCode"
              placeholder="短信验证码"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <button
            type="button"
            :class=" isDisabled ? 'code-btn-disabled' : 'code-btn' "
            @click="sendVerifyCode(regForm.phone, 'login', 'loginCaptcha')"
            :disabled = "isDisabled"
          >
            {{ isDisabled ? `重新发送(${timeCount}s)` : '获取验证码' }}
          </button>
        </div>
        <el-form-item prop="password" v-if="passwordLogin">
          <el-input
            type="password"
            v-model="loginForm.password"
            autocomplete="new-password"
            placeholder="请输入密码"
            @keyup.enter.native="submitForm('loginForm')"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('loginForm')"
            >提交</el-button
          >
          <el-button @click="resetForm('loginForm')">重置</el-button>
        </el-form-item>
        <div style="display: flex; justify-content: center; width: 168px; font-size: 12px;">
          <!-- <a style="cursor: pointer;" @click="toggleLoginMethod" v-if="!passwordLogin">密码登录</a> -->
          <!-- <a style="cursor: pointer;" @click="toggleLoginMethod" v-if="passwordLogin">验证码登录</a> -->
          <a style="cursor: pointer;" @click="toggleActive(); setIsReset(true)">重置密码</a>
        </div>
      </el-form>
    </div>
    <div class="overlay-container">
      <div class="overlay">
        <div class="overlay-panel overlay-left">
          <h1 class="overlay-title">帛马</h1>
          <p class="overlay-text">用框架，助力思考</p>
          <button class="ghost" id="signIn" @click="toggleActive">
            直接登录
          </button>
        </div>
        <div class="overlay-panel overlay-right">
          <h1 class="overlay-title">帛马</h1>
          <p class="overlay-text">用框架，助力思考</p>
          <el-button class="ghost" id="signUp" @click="toggleActive(); setIsReset(false)">
            注册账号
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from '../util/UserService'
import MsgService from '../util/MsgService'
import CaptchaMini from 'captcha-mini'

export default {
  data () {
    var validatePass = (rule, value, callback) => {
      if (!/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]{6,20})$/.test(value)) {
        return callback(new Error('密码必须包括字母和数字'))
      }
      callback()
    }
    var validatePass2 = (rule, value, callback) => {
      if (value !== this.regForm.password) {
        return callback(new Error('两次输入密码不一致!'))
      }
      callback()
    }
    var validatePhone = (rule, value, callback) => {
      if (
        !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
          value
        )
      ) {
        return callback(new Error('请输入正确的手机号'))
      }
      callback()
    }
    return {
      inputCaptcha: '',
      correctCaptcha: '',
      active: true,
      passwordLogin: true,
      isReset: false,
      isDisabled: false,
      timeCount: 60,
      regForm: {
        phone: '',
        password: '',
        checkPass: '',
        verifyCode: ''
      },
      loginForm: {
        phone: '',
        password: '',
        verifyCode: ''
      },
      rules: {
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePhone, trigger: 'blur' }
        ],
        verifyCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
          {
            min: 6,
            max: 6,
            message: '验证码为 6 位数字',
            trigger: 'blur'
          }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 16,
            message: '密码长度在 6 到 16 个字符',
            trigger: 'blur'
          },
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            min: 6,
            max: 16,
            message: '密码长度在 6 到 16 个字符',
            trigger: 'blur'
          },
          { validator: validatePass2, trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    // 查看state里的登录状态，如果已经登录，直接去文档列表
    if (this.$store.getters.getIsLogin) {
      return this.$router.push('/document')
    }
  },
  mounted () {
    this.generateCaptcha('registerCaptcha')
  },
  methods: {
    // 注册/登录界面切换
    toggleActive () {
      this.active = !this.active
      this.inputCaptcha = ''
    },
    // 切换登录方式
    toggleLoginMethod () {
      this.passwordLogin = !this.passwordLogin
      const div = document.getElementById('captchaDiv')
      if (div.style.display === 'none') {
        div.style.display = 'flex'
      } else {
        div.style.display = 'none'
      }
      this.generateCaptcha('loginCaptcha')
    },
    // 重置密码
    setIsReset (status) {
      this.isReset = status
      this.generateCaptcha('resetCaptcha')
    },
    // 倒计时
    countDown () {
      this.isDisabled = true
      const timer = setInterval(() => {
        --this.timeCount
      }, 1000)
      // 清除倒计时
      setTimeout(() => {
        clearInterval(timer)
        this.timeCount = 60
        this.isDisabled = false
      }, 60000)
    },
    async sendVerifyCode (phone, type, domName) {
      // 验证校验码
      if (this.inputCaptcha !== this.correctCaptcha) {
        this.$message({
          type: 'warning',
          message: '请输入正确的校验码',
          offset: 52
        })
        this.generateCaptcha(domName)
        this.inputCaptcha = ''
        return
      }
      // 如果手机号不存在或者不符合格式，提醒
      if (
        !phone ||
        !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
          phone
        )
      ) {
        this.$message({
          type: 'warning',
          message: '请输入正确的手机号',
          offset: 52
        })
        return
      }

      const result = await UserService.getCode(phone, type)
      MsgService.showMsgByCode(result.code, result.message)

      if (result.code === '60001') {
        this.countDown()
      }
    },
    // 生成校验码
    generateCaptcha (domName) {
      const captcha = new CaptchaMini({
        lineWidth: 1, // 线条宽度
        lineNum: 3, // 线条数量
        dotR: 1, // 点的半径
        dotNum: 8, // 点的数量
        // preGroundColor: [10, 80], // 前景色区间
        // backGroundColor: [150, 250], // 背景色区间
        fontSize: 24, // 字体大小
        fontFamily: ['Georgia', 'Helvetica', 'Arial'], // 字体类型
        fontStyle: 'stroke', // 字体绘制方法，有fill和stroke
        content: 'abcdefghijklmnopqrstuvwxyz123456789', // 验��码内容
        length: 4 // 验证码长度
      })
      captcha.draw(document.querySelector('#' + domName), r => {
        this.correctCaptcha = r
      })
    },
    // 请求用户注册
    async register (user) {
      return await UserService.register(user)
    },
    // 请求用户登录
    async login (user) {
      return await UserService.login(user)
    },
    async reset (user) {
      return await UserService.reset(user)
    },
    // 处理表单提交
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        // 处理注册
        if (formName === 'regForm') {
          this.register(this.regForm).then((result) => {
            MsgService.showMsgByCode(result.code, result.message)
            // 如果用户已经注册，提示并无动作
            if (result.code === '70002' || result.code === '70003') return
            // 如果注册成功，保存localStorage和store
            if (result.code === '60000') {
              this.saveToLocalStorage(result)
              this.saveToStore(result)
            }
            // 如果有页面来源，跳转到前一页，没有的话去doc页
            // if (!localStorage.getItem('from')) {
            //   return this.$router.push('/document')
            // }
            // this.$router.push(localStorage.getItem('from'))
            // 202212修改，登录注册后，去板列表里
            this.$router.push('/template')
          })
        }
        // 处理登录
        if (formName === 'loginForm') {
          this.login(this.loginForm).then((result) => {
            MsgService.showMsgByCode(result.code, result.message)
            // 如果出错，不做任何操作
            if (result.code !== '60001') return
            // 登录成功，前端存储信息
            this.saveToLocalStorage(result)
            this.saveToStore(result)
            // 如果缓存里没有来源就去doc页，如果有就去from页
            // if (!localStorage.getItem('from')) {
            //   return this.$router.push('/document')
            // }
            // this.$router.push(localStorage.getItem('from'))
            // 202212修改，登录注册后，去模板列表里
            this.$router.push('/template')
          })
        }
        // 处理重置密码
        if (formName === 'resetForm') {
          this.reset(this.regForm).then((result) => {
            MsgService.showMsgByCode(result.code, result.message)
            // 如果用户已经注册，提示并无动作
            // if (result.code === '70002' || result.code === '70003' || result.code === '70004') return
            // 如果注册成功，保存localStorage和store
            if (result.code !== '60000') return
            this.saveToLocalStorage(result)
            this.saveToStore(result)
            // 如果有页面来源，跳转到前一页，没有的话去doc页
            // if (!localStorage.getItem('from')) {
            //   return this.$router.push('/document')
            // }
            // this.$router.push(localStorage.getItem('from'))
            // 202212修改，登录注册后，去模板列表里
            this.$router.push('/template')
          })
        }
      })
    },
    // 用户注册/登录后保存token到本地
    saveToLocalStorage (userData) {
      localStorage.setItem('token', userData.token)
      localStorage.setItem('refreshToken', userData.refreshToken)
    },
    // 用户注册/登录后保存登录状态到store
    saveToStore (userData) {
      this.$store.dispatch('setIsLogin', true)
      this.$store.dispatch('setUserGroup', userData.user_group)
      this.$store.dispatch(
        'setUserGroupExpiredAt',
        userData.user_group_expiredAt
      )
      this.$store.dispatch('setPhone', userData.phone)
    },
    // 重置表单
    resetForm (formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style scoped>
h1 {
  font-weight: bold;
  color: #4f46e5;
  text-align: center;
  margin-bottom: 20px;
}

h2 {
  text-align: center;
  line-height: 60px;
  color: #4f46e5;
}

p {
  font-size: 14px;
  font-weight: 100;
  line-height: 60px;
  letter-spacing: 0.5px;
  color: #4b5563;
}

span {
  font-size: 12px;
  color: #4b5563;
}

a {
  color: #6366f1;
  font-size: 14px;
  line-height: 20px;
  transition: color 0.3s ease;
  &:hover {
    color: #4f46e5;
    text-decoration: none;
  }
}

button {
  border-radius: 20px;
  border: 1px solid #6366f1;
  background: linear-gradient(120deg, #6366f1 0%, #8b5cf6 100%);
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: all 0.3s ease;
  &:active {
    transform: scale(0.95);
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background: linear-gradient(120deg, #4f46e5 0%, #7c3aed 100%);
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);
  }
}

button.ghost {
  background-color: transparent;
  border-color: #ffffff;
  cursor: pointer;
}

form {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(99, 102, 241, 0.08);
}

.container {
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 14px 28px rgba(99, 102, 241, 0.25), 0 10px 10px rgba(99, 102, 241, 0.22);
  position: relative;
  overflow: hidden;
  width: 768px;
  max-width: 100%;
  min-height: 480px;
  margin: 60px auto;
}

.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}

.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
}

.container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  animation: show 0.6s;
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background: #6366f1;
  background: -webkit-linear-gradient(120deg, #6366f1 0%, #8b5cf6 100%);
  background: linear-gradient(120deg, #6366f1 0%, #8b5cf6 100%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
  color: #ffffff;

  h1 {
    color: #ffffff !important;
  }

  p {
    color: #ffffff !important;
  }
}

.overlay-left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}

.form {
  background-color: #fff;
}

.code-btn {
  margin-left: 4px;
  width: 82px;
  height: 40px;
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  background: linear-gradient(120deg, #6366f1 0%, #8b5cf6 100%);
  color: white;
  border: none;
  transition: all 0.3s ease;
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);
  }
}

.code-btn-disabled {
  margin-left: 4px;
  width: 82px;
  height: 40px;
  padding: 4px;
  border-radius: 4px;
  background: #999;
  border: 0;
  color: #fff;
}

.overlay-title {
  color: #ffffff !important;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.overlay-text {
  color: #ffffff !important;
  font-size: 14px;
  font-weight: 100;
  line-height: 60px;
  letter-spacing: 0.5px;
}
</style>
