<template>
  <div>
    <div style="text-align: left">
      <el-row>
        <el-button type="success" @click="createNew">选择模板新建</el-button>
        <el-button type="success" @click="createBlankDoc">新建空白文档</el-button>
      </el-row>
    </div>
    <el-table
      :data="
        tableData.filter(
          (data) =>
            !search ||
            data.doc_title.toLowerCase().includes(search.toLowerCase())
        )
      "
      style="width: 100%"
      stripe
      :default-sort = "{prop: 'updatedAt', order: 'descending'}"
    >
      <el-table-column label="文档名" prop="doc_title" sortable>
        <template slot-scope="scope">
          <a :href="'/#/doc/' + scope.row._id" style="text-decoration: none; color: #606266;">{{scope.row.doc_title}}</a>
        </template>
      </el-table-column>
      <!-- <el-table-column label="模板" prop="template_title" sortable> </el-table-column> -->
      <el-table-column label="修改时间" prop="updatedAt" sortable> </el-table-column>
      <el-table-column align="right">
        <template #header>
          <el-input v-model="search" size="small" placeholder="搜索文件名" />
        </template>
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="success"
            @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-button size="mini" @click="confirmDelete(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import DocService from '../util/DocService'
import TokenService from '../util/TokenService'

export default {
  data () {
    return {
      token: null,
      tableData: [],
      search: ''
    }
  },
  async created () {
    // 查看state里的登录状态，如果没登录去登录
    if (!this.$store.getters.getIsLogin) {
      localStorage.setItem('from', this.$route.path)
      return this.$router.push('/login')
    }
    // 验证登录获取token
    const token = await TokenService.checkToken()
    if (!token) {
      localStorage.setItem('from', this.$route.path)
      return this.$router.push('/login')
    }
    this.token = token
    // 获取文档列表
    this.getDocs()
    document.title = '我的文档 - 帛马'
  },
  methods: {
    // 获取文档列表
    async getDocs () {
      const docList = await DocService.getDocs(this.token)
      this.tableData = docList
    },
    // 创建文档按钮，先去选择模板
    createNew () {
      this.$router.push('/template')
    },
    // 创建空白文档
    createBlankDoc () {
      this.$router.push('/temp/62ce5398c3c16f51c604ade1')
    },
    // 编辑文档
    handleEdit (index, row) {
      this.$router.push('/doc/' + row._id)
    },
    // 删除文档确认
    confirmDelete (index, row) {
      this.$confirm('确认删除该文档？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.deleteDoc(row._id)
        })
        .catch(() => {})
    },
    // 删除文档
    async deleteDoc (id) {
      const doc = await DocService.deleteDoc(this.token, id)
      if (doc.code === 60005) {
        this.$message({
          type: 'success',
          message: '删除成功',
          offset: 52
        })
        this.getDocs()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/* 新建按钮样式 */
.el-row .el-button--success {
  background: linear-gradient(120deg, #6366f1 0%, #8b5cf6 100%);
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  margin-right: 2px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  height: 44px;
  font-size: 14px;
  line-height: 20px;
  &:hover {
    background: linear-gradient(120deg, #4f46e5 0%, #7c3aed 100%);
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);
  }

  &:active {
    transform: translateY(0);
    box-shadow: 0 2px 8px rgba(99, 102, 241, 0.2);
  }
}

/* 表格中编辑按钮的样式 */
.el-table .el-button--mini.el-button--success {
  background: linear-gradient(120deg, #6366f1 0%, #8b5cf6 100%);
  border: none;
  color: white;
  margin-right: 8px;
  height: auto;
  padding: 7px 15px;
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);
  }
}

.el-table .el-button--mini {
  padding: 7px 15px;
  margin: auto 0;
  line-height: 1.5;
}

/* 表格样式优化 */
.el-table {
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(99, 102, 241, 0.08);
}

.el-table th {
  background-color: #f8fafc !important;
  color: #4f46e5;
  font-weight: 600;
  border-bottom: 1px solid rgba(99, 102, 241, 0.1);
}

.el-table td {
  border-bottom: 1px solid rgba(99, 102, 241, 0.1);
}

.el-table tr:hover > td {
  background-color: rgba(99, 102, 241, 0.05) !important;
}

/* 搜索框样式 */
.el-input__inner {
  border-radius: 8px;
  border: 1px solid rgba(99, 102, 241, 0.2);
  &:focus {
    border-color: #6366f1;
    box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.1);
  }
}

/* 表格中的按钮样式 */
.el-button--mini {
  border-radius: 6px;
  transition: all 0.3s ease;
}

.el-button--mini.el-button--success {
  background: linear-gradient(120deg, #6366f1 0%, #8b5cf6 100%);
  border: none;
  color: white;
  margin-right: 8px;
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(99, 102, 241, 0.2);
  }
}

.el-button--mini:not(.el-button--success) {
  border: 1px solid rgba(99, 102, 241, 0.2);
  color: #6366f1;
  &:hover {
    color: #4f46e5;
    border-color: #4f46e5;
    background-color: rgba(99, 102, 241, 0.05);
  }
}

/* 文链接样式 */
a {
  color: #606266 !important;
  transition: all 0.3s ease;
  &:hover {
    color: #6366f1 !important;
    text-decoration: none;
  }
}

/* 分页器样式优化 */
.el-pagination {
  margin-top: 20px;
}

.el-pagination .btn-prev, .el-pagination .btn-next {
  border-radius: 8px;
}

.el-pagination .el-pager li {
  border-radius: 8px;
}

.el-pagination .el-pager li.active {
  background: linear-gradient(120deg, #6366f1 0%, #8b5cf6 100%);
  color: white;
}

/* 确认框样式 */
.el-message-box {
  border-radius: 16px;
  overflow: hidden;
}

.el-message-box__header {
  background: linear-gradient(120deg, #6366f1 0%, #8b5cf6 100%);
  padding: 15px 20px;
}

.el-message-box__title {
  color: white;
}

.el-message-box__btns .el-button--primary {
  background: linear-gradient(120deg, #6366f1 0%, #8b5cf6 100%);
  border: none;
  border-radius: 8px;
}
</style>
