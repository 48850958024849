<template lang="">
  <div>
    <Login></Login>
  </div>
</template>

<script>
import Login from '../components/Login.vue'

export default {
  created () {
    document.title = '注册登录 - 帛马'
  },
  components: {
    Login
  }
}
</script>
